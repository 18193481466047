<template>
  <div>
    <VerifyCredentialsDialog :dialogVerificationRequests="dialogVerificationRequests" :StoreObj="StoreObj" @clicked="dialogVerificationRequestsEmit" />
    <v-card class="elevation-0">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="largeFontSizeNew">Verification Requests</div>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-card outlined>
          <v-data-table
            dense
            class="elevation-0"
            :headers="GetAllVerificationRequestsListHeaders"
            :items="GetAllVerificationRequestsList"
            :footer-props="{
              'items-per-page-options': [100, 200, 300, 400, 500],
            }"
            :items-per-page="100"
            :no-data-text="noDataText"
          >
            <template v-slot:[`item.sl_no`]="{ item }">
              <div class="FontSize">{{ GetAllVerificationRequestsList.indexOf(item) + 1 }}</div>
            </template>
            <template v-slot:[`item.user_name`]="{ item }">
              <div class="FontSize">
                {{ item.user_name }}
              </div>
            </template>
            <template v-slot:[`item.request_created_on`]="{ item }">
              <div class="FontSize">
                {{ new Date(item.request_created_on).toLocaleString() }}
              </div>
            </template>
            <template v-slot:[`item.user_email_id`]="{ item }">
              <div class="FontSize">
                {{ item.user_email_id }}
              </div>
            </template>
            <template v-slot:[`item.user_contact_number`]="{ item }">
              <div class="FontSize">{{ item.user_country_code }}-{{ item.user_contact_number }}</div>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <div class="FontSize">{{ item.type.includes("_") == true ? item.type.replace("_", " ") : item.type }}</div>
            </template>
            <template v-slot:[`item.transaction_status`]="{ item }">
              <div class="FontSize" :class="item.transaction_status == 'PENDING' ? 'blue--text' : 'green--text'">{{ item.transaction_status }}</div>
            </template>
            <template v-slot:[`item.Action`]="{ item }">
              <v-btn icon color="green" @click="checkItem(item)">
                <v-icon>mdi-magnify-scan</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import VerifyCredentialsDialog from "@/components/VerificationRequests/Dialogs/VerifyCredentialsDialog.vue";
import { GetAllVerificationRequests } from "@/mixins/GetAllVerificationRequests.js";
export default {
  mixins: [GetAllVerificationRequests],
  components: {
    VerifyCredentialsDialog,
  },
  data: () => ({
    noDataText: "",
    StoreObj: {},
    dialogVerificationRequests: false,
    GetAllVerificationRequestsListHeaders: [
      {
        text: "Sl. No",
        value: "sl_no",
      },
      {
        text: "Request By",
        value: "user_name",
      },
      {
        text: "Request On",
        value: "request_created_on",
      },
      {
        text: "Email ID",
        value: "user_email_id",
      },
      {
        text: "Contact No.",
        value: "user_contact_number",
      },
      {
        text: "Verification Type",
        value: "type",
      },
      {
        text: "Status",
        value: "transaction_status",
      },
      {
        text: "Action",
        value: "Action",
      },
    ],
  }),
  mounted() {
    this.GetAllVerificationRequestsMethod();
  },
  methods: {
    dialogVerificationRequestsEmit(Toggle) {
      this.dialogVerificationRequests = false;
      if (Toggle == 2) {
        this.GetAllVerificationRequestsMethod();
      }
    },
    checkItem(item) {
      console.warn("Item", item);
      this.dialogVerificationRequests = true;
      this.StoreObj = item;
    },
  },
};
</script>
