<template>
  <v-card class="elevation-0">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="7">
          <v-card class="elevation-0">
            <v-row class="px-8 py-9">
              <v-col cols="12" xs="12" sm="12" md="12" class="largeFontSize mb-5"
                >An Innovative System for <br />
                Tamper-Proofing Digital Records</v-col
              >
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                class="mediumFontSize pt-4"
                >SureCreds is a blockchain-based system used for <br />
                issuing and verifying digital credentials
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                class="smallFontSize my-n2"
              >
                <ul>
                  <li>
                    Issue digitally verifiable tamper-proof credentials at a
                    very affordable cost
                  </li>
                </ul>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                class="smallFontSize my-n2"
              >
                <ul>
                  <li>
                    Use your existing formats, or use the built-in Template
                    Designer
                  </li>
                </ul>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                class="smallFontSize my-n2"
              >
                <ul>
                  <li>
                    Verify the credentials for FREE, even if you do not have a
                    SureCreds account
                  </li>
                </ul>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                class="smallFontSize my-n2"
              >
                <ul>
                  <li>
                    Integrate SureCreds with your enterprise systems using open
                    APIs
                  </li>
                </ul>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                class="smallFontSize my-n2"
              >
                <ul>
                  <li>
                    Request credential from eligible issuers (charges apply)
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="5" class="mt-14" align="center">
          <v-card class="elevation-0 mt-3">
            <v-img max-width="700px" src="@/assets/Surecredslaptop.png"></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        src: "@/assets/Image2.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
      },
    ],
  }),
  mounted() {},
  methods: {},
};
</script>
