<template>
  <div>
    <Overlay :overlay="overlay" :count="count" />
    <v-card class="elevation-0">
      <v-card-text class="py-0 px-4">
        <v-row no-gutters>
          <v-col
            v-if="results == false"
            cols="12"
            xs="12"
            sm="12"
            :md="results == false ? 6 : 6"
            align="center"
          >
            <v-card
              class="BorderPrimary elevation-0 my-5 mb-13"
              dense
              align="center"
              justify="center"
              :height="heightRight"
              @drop.prevent="onDrop($event)"
              @dragover.prevent="dragover = true"
              @dragenter.prevent="dragover = true"
              @dragleave.prevent="dragover = false"
              @click="handleUpload"
            >
              <v-card-text style="padding-top: 150px">
                <v-btn
                  small
                  tile
                  icon
                  :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
                  class="mr-2 text-capitalize"
                  color="primary"
                >
                  <v-icon size="60">mdi-cloud-upload</v-icon>
                  <input
                    ref="excel-upload-input"
                    accept="application/pdf"
                    class="excel-upload-input"
                    type="file"
                    @change="handleClick"
                  />
                </v-btn>
                <p
                  v-if="uploadedFiles.length == 0"
                  class="mt-4 font-weight-bold"
                  style="color: #00a650"
                >
                  Drop your file here, or click to select them.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            v-if="results == true"
            cols="12"
            xs="12"
            sm="12"
            md="6"
            align="center"
          >
            <v-card
              v-if="responseStatus == 'CONFIRMED'"
              class="BorderGreen elevation-0 mt-7"
              color="#ecfaec"
              :height="heightRight"
            >
              <v-card-title class="pt-3">
                <div class="largeFontSizePrimaryGreen">Valid Credentials</div>
              </v-card-title>
              <v-card-text align="left">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Issued On</v-list-item-title>
                    <v-list-item-subtitle
                      v-text="credentials.issued_on"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Issued By Org.</v-list-item-title>
                    <v-list-item-subtitle
                      v-text="credentials.issued_at"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Issued By User Name</v-list-item-title>
                    <v-list-item-subtitle
                      v-text="credentials.issued_by_user_name"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Issued To Receipient </v-list-item-title>
                    <v-list-item-subtitle
                      v-text="credentials.issued_to"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  dark
                  color="green"
                  @click.stop="
                    (results = false), (dragover = false), (uploadedFiles = [])
                  "
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
            <v-card
              v-if="responseStatus == 'MINING'"
              class="BorderOrange elevation-0 mt-7"
              color="#fff6e5"
              :height="heightRight"
            >
              <v-card-title class="pt-3">
                <div class="largeFontSizePrimaryOrange">
                  Writing To Bloackchain
                </div>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  dark
                  color="orange"
                  @click.stop="
                    (stepVerifyCredentials = 1),
                      (error = false),
                      (dragover = false),
                      (uploadedFiles = []),
                      (results = false)
                  "
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
            <v-card
              v-if="responseStatus == 'ERROR'"
              :height="heightRight"
              class="elevation-0 BorderRed elevation-0 mt-7"
            >
              <div align="left" class="largeFontSizeRed py-4 pl-2">
                Invalid Credentials
              </div>
              <v-list-item align="left" two-line>
                <v-list-item-content>
                  <v-list-item-title>File Name</v-list-item-title>
                  <v-list-item-subtitle
                    v-text="uploadedFiles[0].name"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  dark
                  color="red"
                  @click.stop="removeFile(uploadedFiles[0].name)"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            :md="results == false ? 6 : 6"
            align="center"
            class=""
          >
            <v-card
              class="elevation-0"
              style="padding-top: 100px"
              align="center"
              v-if="results == false"
            >
              <v-img
                max-width="320px"
                src="@/assets/VerifyCredentials.jpeg"
              ></v-img>
            </v-card>
            <v-card
              class="elevation-0 mx-8 mt-7"
              align="left"
              v-if="results == false"
            >
              <div class="xlargeFontSize">Verify Credentials</div>
              <div class="mediumFontSize mt-2" align="left">
                Check the authenticity of a SureCreds issued document
              </div>
            </v-card>
            <v-row v-if="results == true" class="BorderPrimary mt-7 mx-4">
              <div class="xlargeFontSize py-2 pl-2">Selected Document</div>
              <iframe
                :height="heightPDF"
                :src="pdfsrc"
                width="100%"
                frameborder="0"
              ></iframe>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { API } from "aws-amplify";
import Overlay from "@/components/Extras/Overlay.vue";
export default {
  name: "Upload",
  props: {
    dialogUpload: Boolean,
    countCreds: Number,
  },
  components: {
    Overlay,
  },
  data: () => ({
    text: "",
    data: "",
    count: 0,
    height: 0,
    pdfsrc: "",
    snackbar: false,
    dragover: false,
    uploadedFiles: [],
    multiple: false,
    overlay: false,
    SnackBarComponent: {},
    stepVerifyCredentials: 1,
    dialogViewCredentials: false,
    listCredentialsHeaders: [
      { text: "Issued To", value: "issued_to_email_id" },
      { text: "Issued On", value: "issued_on" },
      { text: "Issued At", value: "issued_at" },
      // { text: "Issued By", value: "issued_by_user_name" },
    ],
    error: false,
    results: false,
    Height: "400px",
    verifyInterval: 0,
    heightRight: 0,
    credentials: {
      issued_to: "",
      issued_on: "",
      issued_at: "",
      issued_by_user_name: "",
    },
    responseStatus: "",
  }),
  watch: {
    countCreds() {
      this.results = false;
    },
    dialogUpload() {
      this.uploadedFiles = [];
    },
  },
  mounted() {
    this.height = window.innerHeight - 210;
    this.heightPDF = window.innerHeight - 175;
    this.heightRight = window.innerHeight - 120;
  },
  methods: {
    dialogViewCredentialsEmit() {
      this.dialogViewCredentials = false;
    },
    async VerifyCredentials(data) {
      var self = this;
      self.count = 60;
      self.overlay = true;
      const path = "verifycredentials";
      const myInit = {
        body: {
          command: "verifyCredential",
          credential_hash: data,
        },
      };
      API.post("RESTAPI", path, myInit)
        .then((response) => {
          self.responseStatus = response.status;
          self.count = 80;
          self.overlay = false;
          self.results = true;
          if (!response.errorType) {
            if (self.responseStatus == "CONFIRMED") {
              self.credentials = {
                issued_to: response.data.issued_to,
                issued_on: response.data.issued_on,
                issued_at: response.data.issued_at,
                issued_by_user_name: response.data.issued_by_user_name,
              };
            }
          } else {
            self.responseStatus = "ERROR";
          }
        })
        .catch((error) => {
          console.warn(error);
          self.count = 0;
          self.overlay = true;
          self.results = true;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    uploadFiles() {
      this.count = 0;
      this.overlay = true;
      var fileReader = new FileReader();
      this.count = 20;
      fileReader.readAsDataURL(this.uploadedFiles[0]);
      var self = this;
      fileReader.onload = async function (val) {
        self.count = 40;
        self.pdfsrc = val.target.result;
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        const crypto = require("crypto");
        let data = crypto.createHash("sha256").update(buf).digest("hex");
        self.VerifyCredentials(data);
      };
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.uploadedFiles = [];
      this.dragover = true;
      if (e.target.files.length == 1) {
        for (let i = 0; i < e.target.files.length; i++) {
          this.uploadedFiles.push(e.target.files[i]);
        }
        this.uploadFiles();
      }
    },
    closeDialog() {
      this.uploadedFiles = [];
    },
    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName
      );
      if (index > -1) this.uploadedFiles.splice(index, 1);
      this.dragover = false;
      this.error = false;
      this.results = false;
    },
    onDrop(e) {
      this.uploadedFiles = [];
      if (e.dataTransfer.files.length == 1) {
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          this.uploadedFiles.push(e.dataTransfer.files[i]);
        }
        this.uploadFiles();
      }
    },
  },
};
</script>
<style scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
</style>
