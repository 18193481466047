import { API } from "aws-amplify";
export const GetAllTemplatesRESTAPI = {
  data() {
    return {
      GetAllTemplatesList: [],
    };
  },
  methods: {
    async GetAllTemplatesMethod(issuer_id) {
      var self = this;
      const path = "listtemplates";
      const myInit = {
        body: {
          command: "listAllTemplates",
          issuer_id: issuer_id,
        },
      };
      API.post("SURECREDS", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            self.GetAllTemplatesList = response.data.items;
          } else {
            self.GetAllTemplatesList = [];
            self.noDataText = response.errorMessage;
          }
        })
        .catch(() => {});
    },
  },
};
