<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-card class="elevation-0">
      <v-stepper v-model="e1" outlined class="elevation-0">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1"
            >Identify Yourself</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2"
            >Select The Issuer</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 3" step="3"
            >Select Credential Type</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 4" step="4">
            Confirm request</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step step="5">Make payment</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card class="mb-4 elevation-0" outlined :height="srcHeight">
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card
                    :height="innerHeight"
                    class="elevation-0"
                    align="center"
                  >
                    <v-card-title>
                      <div>Requester details</div>
                    </v-card-title>
                    <v-card-text>
                      <v-card class="elevation-0 mt-6" max-width="400px">
                        <v-form ref="formRequester">
                          <v-row no-gutters>
                            <v-col cols="12" xs="12" sm="12" md="12">
                              <v-text-field
                                dense
                                outlined
                                class="
                                  FontSize
                                  field_height field_label_size
                                  mr-1
                                "
                                label="Name *"
                                v-model="requester_details.issued_to"
                                :rules="[(v) => !!v || 'Required']"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12">
                              <v-text-field
                                dense
                                outlined
                                class="
                                  FontSize
                                  field_height field_label_size
                                  mr-1
                                "
                                label="Email ID *"
                                v-model="requester_details.issued_to_email_id"
                                :rules="[
                                  (v) => !!v || 'Email ID Is Required',
                                  (v) =>
                                    /.+@.+/.test(v) || 'Email Must Be Valid',
                                ]"
                              ></v-text-field>
                              <div
                                class="FontSizeXSmall mt-n4 mb-4"
                                align="left"
                              >
                                On successful processing, the credential will be
                                mailed to this email address.
                              </div>
                            </v-col>
                            <v-row no-gutters>
                              <v-col cols="12" xs="12" sm="12" md="4">
                                <v-select
                                  outlined
                                  dense
                                  :items="GetAllCountriesList"
                                  item-value="country_code"
                                  item-text="country_name"
                                  class="field_height field_label_size FontSize"
                                  label="Country Code"
                                  :rules="[(v) => !!v || 'Required']"
                                  v-model="
                                    requester_details.issued_to_country_code
                                  "
                                >
                                  <template
                                    slot="selection"
                                    slot-scope="{ item }"
                                  >
                                    {{ item.country_code }}
                                  </template>
                                  <template slot="item" slot-scope="{ item }"
                                    >{{ item.country_name }}({{
                                      item.country_code
                                    }})
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col cols="12" xs="12" sm="12" md="8">
                                <v-text-field
                                  dense
                                  outlined
                                  class="
                                    FontSize
                                    field_height field_label_size
                                    ml-1
                                  "
                                  label="Mobile Number *"
                                  v-model="
                                    requester_details.issued_to_contact_number
                                  "
                                  :rules="[
                                    (v) =>
                                      /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(
                                        v
                                      ) || 'Invalid',
                                    (v) => (v && v.length < 11) || 'Invalid',
                                    (v) => (v && v.length > 9) || 'Invalid',
                                  ]"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <div class="FontSizeXSmall mt-n4" align="left">
                              On successful processing, the credential will be
                              sent by WhatsApp to this number.
                            </div>
                          </v-row>
                        </v-form>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" align="center">
                  <v-card height="100%" outlined class="elevation-0">
                    <v-card-text class="mt-16">
                      <div align="left" class="mt-10 font-weight-black">
                        As an individual, you can request for your
                        credential/certificate by selecting the appropriate
                        issuer from the search result list and making your
                        submission. Note that only certain issuers are enabled
                        for this and there is a fee that needs to be paid for
                        the same, as set by the issuer.
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="validateMethod('ONE')">
                Continue
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card class="mb-4 overflow-y-auto" outlined :height="srcHeight">
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="12" md="7">
                  <v-card
                    outlined
                    :height="innerHeight"
                    align="center"
                    class="overflow-y-auto"
                  >
                    <v-card-title>
                      <div>
                        Search For The Issuer To Whom You Want To Send
                        Verification Request
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        :loading="loading"
                        dense
                        outlined
                        placeholder="Search Issuers"
                        v-model="searchKey"
                        append-icon="mdi-magnify"
                      ></v-text-field>
                      <v-card
                        outlined
                        class="elevation-0"
                        v-if="
                          searchKey != '' &&
                          searchKey != null &&
                          searchKey != undefined
                        "
                      >
                        <v-data-table
                          dense
                          class="elevation-0"
                          hide-default-footer
                          :headers="searchResultHeaders"
                          @click:row="handleRowClick"
                          :items="searchResultItems"
                          :footer-props="{
                            'items-per-page-options': [500],
                          }"
                          :items-per-page="500"
                          :no-data-text="noDataText"
                        >
                          <template v-slot:[`item.issuer_name`]="{ item }">
                            <div>
                              <span class="font-weight-bold">Name : </span
                              >{{ item.issuer_name }} ( {{ item.issuer_type }} )
                            </div>
                            <div class="FontSizeSmall">
                              <span class="font-weight-bold">Address : </span
                              >{{ item.issuer_address_1 }},{{
                                item.issuer_address_2
                              }}
                            </div>
                            <div class="FontSizeSmall">
                              <span class="font-weight-bold"
                                >State/ County : </span
                              ><span>{{ item.state }}</span> <b>|</b>
                              <span class="font-weight-bold">District : </span
                              ><span>{{ item.city }}</span> <b>|</b>
                              <span class="font-weight-bold">Area : </span
                              ><span>{{ item.district }}</span> <b>|</b>
                              <span class="font-weight-bold">Country : </span
                              ><span>{{ item.country }}</span>
                            </div>
                          </template>
                          <template v-slot:[`item.Action`]="{}">
                            <v-icon color="primary">mdi-chevron-right</v-icon>
                          </template>
                        </v-data-table>
                      </v-card>
                      <v-card v-else class="elevation-0">
                        <h3>Issuer Can be Searched By Name,Type And Country</h3>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="5">
                  <v-card outlined class="elevation-0" :height="innerHeight">
                    <h3 class="ml-3 pt-5">Your Details</h3>
                    <v-card-text>
                      <div>
                        <span class="font-weight-bold">Name :</span>
                        {{ requester_details.issued_to }}
                      </div>
                      <div>
                        <span class="font-weight-bold">Email ID :</span>
                        {{ requester_details.issued_to_email_id }}
                      </div>
                      <div>
                        <span class="font-weight-bold">Contact Number :</span>
                        {{
                          requester_details.issued_to_country_code +
                          "-" +
                          requester_details.issued_to_contact_number
                        }}
                      </div>
                    </v-card-text>
                    <h3
                      class="ml-3 pt-3"
                      v-if="Object.keys(StoreObj).length != 0"
                    >
                      Issuer Details :
                    </h3>
                    <v-card-text v-if="Object.keys(StoreObj).length != 0">
                      <div>
                        <span class="font-weight-bold">Issuer Name :</span>
                        {{ StoreObj.issuer_name }}
                      </div>
                      <div>
                        <span class="font-weight-bold">Address :</span>
                        {{ StoreObj.issuer_address_1 }},{{
                          StoreObj.issuer_address_2
                        }}
                      </div>
                      <div class="FontSizeSmall">
                        <span class="font-weight-bold">State/ County : </span
                        ><span>{{ StoreObj.state }}</span> <b>|</b>
                        <span class="font-weight-bold">District : </span
                        ><span>{{ StoreObj.city }}</span> <b>|</b>
                        <span class="font-weight-bold">Area : </span
                        ><span>{{ StoreObj.district }}</span> <b>|</b>
                        <span class="font-weight-bold">Country : </span
                        ><span>{{ StoreObj.country }}</span>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn text @click="e1 = 1"> Back </v-btn>
              <v-btn
                :disabled="Object.keys(StoreObj).length == 0"
                color="primary"
                @click="validateMethod('TWO')"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-card class="mb-4" outlined :height="srcHeight">
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card
                    :height="innerHeight"
                    align="left"
                    class="overflow-y-auto"
                  >
                    <v-card-title>
                      <div>Select type of credential being requested</div>
                    </v-card-title>
                    <v-card-text class="py-0">
                      <v-select
                        class="FontSize field_height field_label_size"
                        dense
                        outlined
                        placeholder="Credential Type"
                        v-model="requester_details.credential_type"
                        :items="credentialTypeItems"
                        item-text="text"
                        item-value="value"
                      ></v-select>
                    </v-card-text>
                    <v-card-text
                      class="py-0"
                      v-if="requester_details.credential_type == 'FREE_TEXT'"
                    >
                      <vue-editor
                        ref="imageEditor"
                        :editorToolbar="customToolbar"
                        v-model="requester_details.free_text"
                        placeholder="Add Content"
                      ></vue-editor>
                    </v-card-text>
                    <v-card-text
                      class="pb-0"
                      v-if="
                        current_view == 'CREDENTIALTYPESELECT' &&
                        requester_details.credential_type == 'DOCUMENT'
                      "
                    >
                      <div align="left" class="font-weight-bold">
                        Please Select A Document :
                      </div>
                      <v-file-input
                        accept="application/pdf,image/*,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        label="Select Document"
                        dense
                        outlined
                        clearable
                        class="FontSize"
                        v-model="selectedFile"
                        prepend-icon=""
                        persistent-hint
                        hint="File size should be less than 3 MB"
                      ></v-file-input>
                    </v-card-text>
                    <v-card-text
                      v-if="
                        current_view == 'CREDENTIALTYPESELECT' &&
                        requester_details.credential_type == 'TEMPLATE'
                      "
                    >
                      <v-data-table
                        dense
                        v-if="requester_details.credential_type == 'TEMPLATE'"
                        class="elevation-0"
                        hide-default-footer
                        :headers="templateResultHeaders"
                        @click:row="handleRowClickTemplate"
                        :items="GetAllTemplatesList"
                        :footer-props="{
                          'items-per-page-options': [500],
                        }"
                        :items-per-page="500"
                        :no-data-text="noDataText"
                      >
                        <template v-slot:[`item.template_name`]="{ item }">
                          <div>{{ item.template_name }}</div>
                        </template>
                        <template v-slot:[`item.Action`]="{}">
                          <v-icon color="primary">mdi-chevron-right</v-icon>
                        </template>
                      </v-data-table>
                    </v-card-text>
                    <v-card-text
                      class="pt-0"
                      v-if="
                        current_view == 'TEMPLATEFIELDS' &&
                        requester_details.credential_type == 'TEMPLATE'
                      "
                    >
                      <div align="left" class="font-weight-bold">
                        Please Fill Up The Template Fields :
                      </div>
                      <v-form ref="formFields">
                        <v-text-field
                          v-for="(field, idx) in templateObj.template_fields"
                          :key="idx"
                          dense
                          outlined
                          :rules="[(v) => !!v || 'Required']"
                          class="FontSize field_height field_label_size"
                          :label="field"
                          v-model="formfields[field]"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card :height="innerHeight" class="overflow-y-auto">
                    <v-card
                      v-if="
                        requester_details.credential_type == 'DOCUMENT' &&
                        fileView == true
                      "
                      outlined
                      class="elevation-0"
                    >
                      <div
                        class="py-2 pl-2"
                        style="
                          font-size: 18px;
                          color: #00a650;
                          font-weight: bold;
                        "
                      >
                        Selected Document
                      </div>
                      <iframe
                        :height="innerHeightIframe"
                        :src="fileSRC"
                        width="100%"
                        frameborder="0"
                      ></iframe>
                    </v-card>
                    <v-card
                      v-else-if="
                        requester_details.credential_type == 'TEMPLATE' &&
                        fileView == true
                      "
                      class="elevation-0 overflow-y-auto"
                    >
                      <div
                        class="py-2 pl-2"
                        style="
                          font-size: 18px;
                          color: #00a650;
                          font-weight: bold;
                        "
                      >
                        Selected Template
                      </div>
                      <v-card-text>
                        <div
                          class="px-4 py-2"
                          v-html="this.template_html"
                        ></div>
                      </v-card-text>
                    </v-card>
                    <v-card
                      v-else
                      outlined
                      class="elevation-0"
                      :height="innerHeight"
                    >
                      <h3 class="ml-3 pt-5">Your Details :</h3>
                      <v-card-text>
                        <div>
                          <span class="font-weight-bold">Name :</span>
                          {{ requester_details.issued_to }}
                        </div>
                        <div>
                          <span class="font-weight-bold">Email ID :</span>
                          {{ requester_details.issued_to_email_id }}
                        </div>
                        <div>
                          <span class="font-weight-bold">Contact Number :</span>
                          {{
                            requester_details.issued_to_country_code +
                            "-" +
                            requester_details.issued_to_contact_number
                          }}
                        </div>
                      </v-card-text>
                      <h3
                        class="ml-3 pt-3"
                        v-if="Object.keys(StoreObj).length != 0"
                      >
                        Issuer Details :
                      </h3>
                      <v-card-text v-if="Object.keys(StoreObj).length != 0">
                        <div>
                          <span class="font-weight-bold">Issuer Name :</span>
                          {{ StoreObj.issuer_name }}
                        </div>
                        <div>
                          <span class="font-weight-bold">Address :</span>
                          {{ StoreObj.issuer_address_1 }},{{
                            StoreObj.issuer_address_2
                          }}
                        </div>
                        <div class="FontSizeSmall">
                          <span class="font-weight-bold">State/ County : </span
                          ><span>{{ StoreObj.state }}</span> <b>|</b>
                          <span class="font-weight-bold">District : </span
                          ><span>{{ StoreObj.city }}</span> <b>|</b>
                          <span class="font-weight-bold">Area : </span
                          ><span>{{ StoreObj.district }}</span> <b>|</b>
                          <span class="font-weight-bold">Country : </span
                          ><span>{{ StoreObj.country }}</span>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn text @click="e1 = 2"> Back </v-btn>
              <v-btn color="primary" @click="validateMethod('THREE')">
                Continue
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-card class="mb-4 elevation-0" outlined :height="srcHeight">
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card
                    class="overflow-y-auto"
                    :height="innerHeight"
                    v-if="requester_details.credential_type == 'TEMPLATE'"
                  >
                    <v-card-title class="pb-0">
                      <div>Template Preview</div>
                    </v-card-title>
                    <v-card-text class="pt-0">
                      <div class="px-4" v-html="this.template_html"></div>
                    </v-card-text>
                  </v-card>
                  <v-card
                    class="overflow-y-auto"
                    :height="innerHeight"
                    v-if="requester_details.credential_type == 'DOCUMENT'"
                  >
                    <v-card-title class="pb-0">
                      <div>Document Preview :</div>
                    </v-card-title>
                    <v-card-text class="pt-0">
                      <iframe
                        :height="innerHeightIframe"
                        :src="fileSRC"
                        width="100%"
                        frameborder="0"
                      ></iframe>
                    </v-card-text>
                  </v-card>
                  <v-card
                    class="overflow-y-auto"
                    :height="innerHeight"
                    v-if="requester_details.credential_type == 'FREE_TEXT'"
                  >
                    <v-card-title class="pb-0">
                      <div>Free Text Preview :</div>
                    </v-card-title>
                    <v-card-text class="pt-0">
                      <vue-editor
                        ref="imageEditor"
                        disabled
                        :editorToolbar="customToolbar"
                        v-model="requester_details.free_text"
                      ></vue-editor>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card
                    outlined
                    :height="innerHeight"
                    class="elevation-0 overflow-y-auto"
                  >
                    <h3 class="ml-3 pt-2">Your Details</h3>
                    <v-card-text>
                      <div>
                        <span class="font-weight-bold">Name :</span>
                        {{ requester_details.issued_to }}
                      </div>
                      <div>
                        <span class="font-weight-bold">Email ID :</span>
                        {{ requester_details.issued_to_email_id }}
                      </div>
                      <div>
                        <span class="font-weight-bold">Contact Number :</span>
                        {{
                          requester_details.issued_to_country_code +
                          "-" +
                          requester_details.issued_to_contact_number
                        }}
                      </div>
                    </v-card-text>
                    <h3 class="ml-3 pt-0">Issuer Details :</h3>
                    <v-card-text v-if="Object.keys(StoreObj).length != 0">
                      <div>
                        <span class="font-weight-bold">Issuer Name :</span>
                        {{ StoreObj.issuer_name }}
                      </div>
                      <div>
                        <span class="font-weight-bold">Address :</span>
                        {{ StoreObj.issuer_address_1 }},{{
                          StoreObj.issuer_address_2
                        }}
                      </div>
                      <div class="FontSizeSmall">
                        <span class="font-weight-bold">State/ County : </span
                        ><span>{{ StoreObj.state }}</span> <b>|</b>
                        <span class="font-weight-bold">District : </span
                        ><span>{{ StoreObj.city }}</span> <b>|</b>
                        <span class="font-weight-bold">Area : </span
                        ><span>{{ StoreObj.district }}</span> <b>|</b>
                        <span class="font-weight-bold">Country : </span
                        ><span>{{ StoreObj.country }}</span>
                      </div>
                    </v-card-text>
                    <h3 class="ml-3">Credential Details</h3>
                    <v-card-text>
                      <div>
                        <span class="font-weight-bold">Credential Type :</span>
                        {{ requester_details.credential_type }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn text @click="e1 = 3"> Back </v-btn>
              <v-btn color="primary" @click="validateMethod('FOUR')">
                Continue
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="5" align="center">
            <v-card align="center" class="py-10 elevation-0 ma-2">
              <v-icon size="50px" color="primary">mdi-information</v-icon>
              <v-card-text class="largeFontSize">
                Processing these request will cost you a fee of
                <b
                  >{{ StoreObj.issuer_verification_fee }}
                  {{ StoreObj.currency_type }}</b
                >
                as set by the issuer.
                <br />
                Once the processing of the credential is completed, the same
                will be sent by email to
                <b>{{ requester_details.issued_to_email_id }}</b> and by
                WhatsApp to
                <b
                  >{{ requester_details.issued_to_country_code }}-{{
                    requester_details.issued_to_contact_number
                  }}</b
                >
              </v-card-text>
              <v-card-actions class="pa-0">
                <v-spacer></v-spacer>
                <v-btn
                  small
                  outlined
                  class="borderRadius mr-3"
                  color="primary"
                  @click="$router.push('/Home')"
                  >Cancel</v-btn
                >
                <v-btn
                  small
                  class="borderRadius"
                  color="primary"
                  @click="payment_gateway()"
                  id="rzp-button1"
                  >Make Payment</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
    <br /><br />
  </div>
</template>
<script src="https://checkout.razorpay.com/v1/checkout.js"></script>
<script>
import { API } from "aws-amplify";
import { VueEditor } from "vue2-editor";
import Snackbar from "@/components/Extras/Snackbar.vue";
import Overlay from "@/components/Extras/Overlay.vue";
import { GetSearchedIssuer } from "@/mixins/GetSearchedIssuer.js";
import { GetAllCountriesRESTAPI } from "@/mixins/GetAllCountriesRESTAPI.js";
import { GetAllTemplatesRESTAPI } from "@/mixins/GetAllTemplatesRESTAPI.js";
export default {
  components: {
    Snackbar,
    Overlay,
    VueEditor,
  },
  mixins: [GetSearchedIssuer, GetAllCountriesRESTAPI, GetAllTemplatesRESTAPI],
  data: () => ({
    e1: 1,
    fileSRC: "",
    current_view: "CREDENTIALTYPESELECT",
    fileView: false,
    selectedFile: null,
    srcHeight: 0,
    innerHeight: 0,
    innerHeightIframe: 0,
    searchKey: "",
    searchResultItems: [],
    searchResultHeaders: [
      {
        text: "Issuer Details",
        value: "issuer_name",
      },
      {
        text: "",
        value: "Action",
      },
    ],
    requester_details: {
      issued_to: "",
      issued_to_email_id: "",
      issued_to_country_code: "",
      issued_to_contact_number: "",
      credential_type: "",
      free_text: "",
    },
    credentialTypeItems: [
      {
        text: "Document",
        value: "DOCUMENT",
      },
      {
        text: "Free Text",
        value: "FREE_TEXT",
      },
      {
        text: "Templatized",
        value: "TEMPLATE",
      },
    ],
    SnackBarComponent: {},
    StoreObj: {},
    templateObj: {},
    templateResultHeaders: [
      {
        text: "Template Name",
        value: "template_name",
      },
      {
        text: "",
        value: "Action",
      },
    ],
    formfields: {},
    count: 0,
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"],
    ],
    fileType: "",
    order_id: "",
    key: "",
    overlay: false,
    document_type: "",
  }),
  watch: {
    "requester_details.credential_type"(val) {
      if (val != undefined && val != null && val != "") {
        this.selectedFile = null;
        this.fileView = false;
        this.fileSRC = "";
        this.templateObj = {};
        this.formfields = {};
        this.current_view = "CREDENTIALTYPESELECT";
      }
      if (val == "TEMPLATE") {
        this.GetAllTemplatesMethod(this.StoreObj.issuer_id);
      }
    },
    selectedFile(val) {
      if (val != undefined && val != null && val != "") {
        if (val.size <= 3000000) {
          this.document_type = val.type.includes("pdf")
            ? "PDF"
            : val.type.includes("image")
            ? "IMAGE"
            : val.type.includes(
                "vnd.openxmlformats-officedocument.wordprocessingml.document"
              )
            ? "WORD"
            : "TEXTFILE";
          this.fileType = val.type;
          var fileReader = new FileReader();
          fileReader.readAsDataURL(val);
          var self = this;
          fileReader.onload = async function (val) {
            self.fileSRC = val.target.result;
            self.uploadFilesToS3(self.fileSRC);
            self.fileView = true;
          };
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "File Size Should Be Less Than 1 MB",
          };
        }
      } else {
        this.fileView = false;
        this.fileSRC = "";
      }
    },
    searchKey(val) {
      if (val != undefined && val != null && val != "") {
        this.searchIssuers(val);
      }
    },
  },
  mounted() {
    this.srcHeight = window.innerHeight - 290;
    this.innerHeight = window.innerHeight - 293;
    this.innerHeightIframe = window.innerHeight - 340;
    this.GetAllCountriesMethod();
  },
  methods: {
    async uploadFilesToS3(fileSRC) {
      var axios = require("axios");
      var self = this;
      var config = {
        method: "put",
        url: "https://8l4r1qbuq4.execute-api.ap-south-1.amazonaws.com/surecreds/uploadfile",
        headers: {
          "Content-Type": "application/pdf",
        },
        data: fileSRC.split(",")[1],
      };
      axios(config)
        .then(function (response) {
          self.key = response.data.key;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async razorpayResponse(response) {
      if (response.hasOwnProperty("error")) {
        alert("Payment Unsuccessul.");
      } else {
        this.requestVerification();
      }
    },
    async requestVerification() {
      this.overlay = true;
      let d = new Date();
      let monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let date =
        d.getDate() +
        "-" +
        monthNames[d.getMonth()] +
        "-" +
        d.getFullYear() +
        "  " +
        d.getHours() +
        ":" +
        d.getMinutes() +
        ":" +
        d.getSeconds();
      const path = "createverificationrequest";
      const myInit = {
        body: {
          command: "raiseVerificationRequest",
          order_id: this.order_id,
          issuer_id: this.StoreObj.issuer_id,
          user_name: this.requester_details.issued_to,
          user_email_id: this.requester_details.issued_to_email_id,
          user_country_code: this.requester_details.issued_to_country_code,
          user_contact_number: this.requester_details.issued_to_contact_number,
          type: this.requester_details.credential_type,
          source: "PORTAL",
          date: date,
          month: `${d.getFullYear()}-${d.getMonth() + 1}`,
        },
      };
      if (this.requester_details.credential_type == "FREE_TEXT") {
        myInit.body.free_text = `<html>
                    <body>
                      <div>${this.requester_details.free_text}</div>
                    <body>
                  </html>`;
      }
      if (this.requester_details.credential_type == "TEMPLATE") {
        let template_fields = [];
        for (let key in this.formfields) {
          template_fields.push({
            field_label: key,
            field_value: this.formfields[key],
          });
        }
        myInit.body.template_fields = template_fields;
        myInit.body.template_id = this.templateObj.template_id;
      }
      if (this.requester_details.credential_type == "DOCUMENT") {
        myInit.body.key = this.key;
        myInit.body.file_name = this.selectedFile.name;
        myInit.body.document_type = this.document_type;
      }
      console.warn("myInitmyInitmyInit", myInit);
      await API.post("SURECREDS", path, myInit).then((response) => {
        console.warn("Final response", response);
        if (!response.errorType) {
          this.$router.push("PaymentSuccessful");
        }
        this.overlay = false;
      });
    },
    async payment_gateway() {
      const path = "payment";
      const myInit = {
        body: {
          command: "initialize_razorpay_order",
          amount: this.StoreObj.issuer_verification_fee,
          currency: this.StoreObj.currency_type,
          user_email_id: this.requester_details.issued_to,
        },
      };
      await API.post("SURECREDS", path, myInit)
        .then((response) => {
          this.order_id = response.order_id;
          var options = {
            key: "rzp_live_GZlT4UuNZc96wJ",
            amount: this.StoreObj.issuer_verification_fee * 100,
            currency: this.StoreObj.currency_type,
            name: "SURECREDS",
            description: "SURECREDS",
            image:
              "https://workhistory-mumbai.s3.ap-south-1.amazonaws.com/template_docs/SureCreds+Logo+Hz(1)+(1).png",
            order_id: response.order_id,
            handler: this.razorpayResponse,
            prefill: {
              name: this.requester_details.issued_to,
              email:
                this.requester_details.issued_to_email_id != undefined &&
                this.requester_details.issued_to_email_id != null &&
                this.requester_details.issued_to_email_id != ""
                  ? this.requester_details.issued_to_email_id
                  : "-",
              contact:
                this.requester_details.issued_to_contact_number != undefined &&
                this.requester_details.issued_to_contact_number != null &&
                this.requester_details.issued_to_contact_number != ""
                  ? this.requester_details.issued_to_contact_number
                  : "-",
            },
            notes: {
              address: "",
            },
            theme: {
              color: "#00a650",
            },
          };
          var self = this;
          var rzp1 = new Razorpay(options);
          rzp1.on("payment.failed", function (response) {
            console.warn("Error Response", response);
            self.$router.push("ErrorRoute");
            rzp1.close();
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
          });
          rzp1.open();
        })
        .catch((error) => {
          console.warn("Check The Error", error);
        });
    },
    handleRowClickTemplate(item) {
      this.templateObj = item;
      this.template_html = item.template_html;
      this.templateObj = item;
      this.$forceUpdate();
      this.fileView = true;
      this.current_view = "TEMPLATEFIELDS";
      this.requester_details.free_text = "";
    },
    validateMethod(stepper) {
      switch (stepper) {
        case "ONE":
          if (this.$refs.formRequester.validate()) {
            this.e1 = 2;
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Fields Marked With Asterisks (*) Are Mandatory",
            };
          }
          break;
        case "TWO":
          this.e1 = 3;
          break;
        case "THREE":
          switch (this.requester_details.credential_type) {
            case "DOCUMENT":
              if (
                this.selectedFile != null &&
                this.selectedFile != undefined &&
                this.selectedFile != ""
              ) {
                this.e1 = 4;
              } else {
                this.SnackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  Top: true,
                  SnackbarText: "Please Select Document",
                };
              }
              break;
            case "TEMPLATE":
              if (this.$refs.formFields.validate()) {
                this.enterFieldsInPreview();
                this.e1 = 4;
              } else {
                this.SnackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  Top: true,
                  SnackbarText: "Please Fill Up The Template Fields",
                };
              }
              break;
            case "FREE_TEXT":
              if (
                this.requester_details.free_text != "" &&
                this.requester_details.free_text != undefined &&
                this.requester_details.free_text != null
              ) {
                this.e1 = 4;
              } else {
                this.SnackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  Top: true,
                  SnackbarText: "Please Enter Text",
                };
              }
              break;
          }
          break;
        case "FOUR":
          console.warn("Inside 4");
          this.e1 = 5;
          break;
      }
    },
    enterFieldsInPreview() {
      let newHTML = this.template_html.replaceAll(
        "${" + `${this.templateObj.template_fields[this.count]}` + "}",
        this.formfields[this.templateObj.template_fields[this.count]]
      );
      if (this.count <= this.templateObj.template_fields.length - 1) {
        this.template_html = newHTML;
        this.count += 1;
        this.$forceUpdate();
        this.enterFieldsInPreview();
      } else {
        this.count = 0;
      }
    },
    handleRowClick(item) {
      this.StoreObj = item;
      this.searchKey = "";
    },
  },
};
</script>
