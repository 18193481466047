var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VerifyCredentialsDialog',{attrs:{"dialogVerificationRequests":_vm.dialogVerificationRequests,"StoreObj":_vm.StoreObj},on:{"clicked":_vm.dialogVerificationRequestsEmit}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"largeFontSizeNew"},[_vm._v("Verification Requests")]),_c('v-spacer')],1)],1)],1),_c('v-card-text',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.GetAllVerificationRequestsListHeaders,"items":_vm.GetAllVerificationRequestsList,"footer-props":{
            'items-per-page-options': [100, 200, 300, 400, 500],
          },"items-per-page":100,"no-data-text":_vm.noDataText},scopedSlots:_vm._u([{key:"item.sl_no",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(_vm.GetAllVerificationRequestsList.indexOf(item) + 1))])]}},{key:"item.user_name",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.user_name)+" ")])]}},{key:"item.request_created_on",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(new Date(item.request_created_on).toLocaleString())+" ")])]}},{key:"item.user_email_id",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.user_email_id)+" ")])]}},{key:"item.user_contact_number",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(item.user_country_code)+"-"+_vm._s(item.user_contact_number))])]}},{key:"item.type",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(item.type.includes("_") == true ? item.type.replace("_", " ") : item.type))])]}},{key:"item.transaction_status",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"FontSize",class:item.transaction_status == 'PENDING' ? 'blue--text' : 'green--text'},[_vm._v(_vm._s(item.transaction_status))])]}},{key:"item.Action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.checkItem(item)}}},[_c('v-icon',[_vm._v("mdi-magnify-scan")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }