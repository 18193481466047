<template>
  <div>
    <v-card class="elevation-0">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="12" class="mt-0">
            <div class="largeFontSize mx-5">GET IN TOUCH </div>
            <br/>
            <br/>
            <br/>
            <v-card class="elevation-0 overflow-y-auto">
              <v-row no-gutters class="mx-5">
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize"> SURECREDS PRIVATE LIMITED<br/> No. 290, 11th Main<br/> NTI Layout<br/> Vidyaranyapura <br/>Bangalore – 560097 <br/><br/></v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize"> contactus@surecreds.com<br/><br/></v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize"> +91 90351 17779</v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    height: 0,
  }),
  mounted() {
    this.height = window.innerHeight - 150;
  },
  methods: {},
};
</script>
