<template>
  <div>
    <v-card class="elevation-0">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="12" class="mt-0">
            <div class="largeFontSize mx-5">Frequently Asked Questions</div>
            <v-card class="elevation-0 overflow-y-auto">
              <v-row no-gutters class="mx-5">
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  1. What Is SureCreds ?
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  SureCreds is a SaaS application for issuing and verifying
                  tamper-proof digital credentials. It uses a blockchain-based
                  system that makes it free from the possibility of tampering,
                  loss of critical data and reliance on a critical third party.
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  2. Who Can Use SureCreds?
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  Any organisation that issues credentials like certificates,
                  letters or other documents can use SureCreds to certify the
                  document soft copy. The individual with the SureCreds
                  certified document can present it to any other organisation
                  that seeks to verify these credentials. The document can then
                  be verified in a few clicks on the SureCreds portal. This can
                  avoid much of the time, money and effort spent in background
                  verification.
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  3. Which document formats are supported ?
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  At present, we support input in .pdf, .docx , .txt and all
                  image formats. We are working to support a wide variety of
                  other formats, which will be available in future releases.
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  4. Will the certified document have a different format ?
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  The certified SureCreds document will always be a pdf
                  document. One additional page will be appended to the original
                  document, which will mention the key details that will be
                  stored, along with instructions on how to verify the
                  credential document. The final document will simply be the
                  source document and the appended page together in a single pdf
                  file.
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  5. Do I need to keep a hard copy of the certified document?
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  There is no need for a hard copy at any stage in the process.
                  The issuing organisation will supply a soft copy to the
                  SureCreds system, which will then generate a soft copy
                  certified document. A copy of the certified document is then
                  emailed to the issuer. There is also an option to send a copy
                  to the recipient, or download it later.
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  6. How do I get the credential verified ?
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  The credential can be verified in a few clicks on the
                  SureCreds portal
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  7. How safe is my data ?
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  The SureCreds system stores the hash values of the generated
                  credential and the blockchain transaction hash, for the
                  purpose of enabling verification.The document itself is not
                  stored on the blockchain, though it is kept in an encrypted
                  form downloadable only by the Issuer from the dashboard.
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  8. How is the blockchain used in this solution ?</v-col
                >
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  A few summary data fields relating to the document are stored
                  on the blockchain. These records are immutable,
                  non-tamperable, permanent and transparently available. The
                  portal retrieves these details from the blockchain and makes
                  these available at the time of verification
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  9. How does it work ?</v-col
                >
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  SureCreds is built on top of the Arweave blockchain network.
                  For more details, please refer to www.arweave.org
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  10. How would I benefit as a verifier ?</v-col
                >
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  Verification is simple and free of cost. The SureCreds portal
                  is available to anyone.
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  11. How would we benefit as an issuing organisation?</v-col
                >
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize"
                  >There are several advantages. A credential needs only to be
                  certified once, after which no further validation requests
                  from third parties need to be entertained. They can simply be
                  redirected to use the SureCreds portal. Apart from being
                  relatively inexpensive to issue a credential, there are also
                  savings in human effort which go into validation queries.
                  Moreover, you can help in building this new initiative as a
                  community-wide best practice, by adopting SureCreds in your
                  organisation.</v-col
                >
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  12. How would I benefit as a credential holder ?</v-col
                >
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize"
                  >You can request a past employer or institution to issue a
                  SureCreds credential to you, through a few clicks on the
                  portal. You can request the same from a current employer or
                  associated institution. Once issued, you will need only the
                  soft copy which can be verified easily, eliminating the need
                  to carry and store originals. You will also save time in
                  getting these verified.</v-col
                >
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="smallFontSize my-3 font-weight-bold"
                >
                  13. Can SureCreds be integrated with our enterprise systems
                  ?</v-col
                >
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize"
                  >Yes, SureCreds provides for open APIs that can accomplish
                  this easily.</v-col
                >
              </v-row>
              <br />
              <br />
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    height: 0,
  }),
  mounted() {
    this.height = window.innerHeight - 150;
  },
  methods: {},
};
</script>
