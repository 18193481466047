<template>
  <div>
    <v-card class="elevation-0">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="12" class="mt-0">
            <div class="largeFontSize mx-5">About Us</div>
            <v-card class="elevation-0 overflow-y-auto">
              <v-row no-gutters class="mx-5">
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize my-3 font-weight-bold"> 1. WHY US ? </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  We have years of experience and have deployed 100s of custom built and platform driven applications for our customers. Our understanding of the ecosystem therefore is very deep,
                  specifically in mobile apps and blockchain technologies for business use cases.
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize my-3 font-weight-bold"> 2.WHO WE ARE? </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  We are a team of 10 developers, focused and passionate about technology. Our collective competencies are in the areas of native app development, server side cloud technologies and
                  blockchain technologies which powers many of the applications we have built.
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize my-3 font-weight-bold"> 3.WHAT WE DO? ? </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  We work closely with customers to understand their specific needs in a consultative mode, with the sole objective of building the solutions in the quickest time with no compromise on
                  quality.
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize my-3 font-weight-bold"> 4.HOW WE WORK? </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="smallFontSize">
                  We use simple common-sense agile methodologies leveraging state of the art development tools. We use automation wherever possible and let machines do most of the drudgery and
                  ourselves focus on the overall architecture, design and creative aspects of the project.
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    height: 0,
  }),
  mounted() {
    this.height = window.innerHeight - 150;
  },
  methods: {},
};
</script>
