<template>
  <v-app>
    <v-app-bar elevation="1" app color="white">
      <v-card max-width="190px" class="elevation-0">
        <v-img src="@/assets/SureCredsLogo.png"></v-img>
      </v-card>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        v-if="$vuetify.breakpoint.name == 'xs'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="primary" dark v-bind="attrs" v-on="on"
            ><v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            class="py-0"
            v-for="(item, i) in itemsFilter"
            :key="i"
            @click="$router.push(item.value)"
          >
            <v-list-item-action class="mr-0">
              <v-icon small color="primary">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="ml-1">
              <v-list-item-title class="FontSize">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <div v-if="$vuetify.breakpoint.name != 'xs'">
        <v-btn
          small
          text
          class="text-capitalize fontStyle"
          style="font-size: 18px"
          :color="$route.name == 'Home' ? 'primary' : '#3d3d3d'"
          @click="pushToRoutes('Home')"
          >Home</v-btn
        >
        <v-btn
          small
          text
          class="text-capitalize fontStyle"
          style="font-size: 18px"
          :color="$route.name == 'FAQs' ? 'primary' : '#3d3d3d'"
          @click="pushToRoutes('FAQs')"
          >FAQs</v-btn
        >
        <v-btn
          small
          text
          class="text-capitalize fontStyle"
          style="font-size: 18px"
          :color="$route.name == 'Login' ? 'primary' : '#3d3d3d'"
          @click="pushToRoutes('Login')"
          >Sign In</v-btn
        >
        <!-- <v-btn
          small
          outlined
          class="text-capitalize fontStyle mr-2"
          style="font-size: 18px"
          :color="$route.name == 'VerificationRequest' ? 'primary' : '#3d3d3d'"
          @click="pushToRoutes('VerificationRequest')"
          >Request Credential</v-btn
        > -->
        <v-btn
          small
          outlined
          class="text-capitalize fontStyle"
          style="font-size: 18px"
          :color="$route.name == 'VerifyCredentials' ? 'primary' : '#3d3d3d'"
          @click="pushToRoutes('VerifyCredentials')"
          >Verify Credential</v-btn
        >
      </div>
    </v-app-bar>
    <v-main id="content">
      <router-view :countCreds="countCreds" />
    </v-main>
    <v-footer>
      <v-row>
        <v-col class="text-center" cols="12" md="2" sm="2">
          <u style="cursor: pointer" @click="$router.push('/AboutUs')"
            >About Us</u
          >
        </v-col>
        <v-col class="text-center ml-9" cols="12" md="2" sm="2">
          <u style="cursor: pointer" @click="$router.push('/ContactUs')"
            >Contact Us</u
          >
        </v-col>
        <v-col class="text-center" cols="12" md="3" sm="3">
          <div>
            <v-icon small color="black">mdi-copyright</v-icon>
            {{ new Date().getFullYear() }} —
            <strong> SureCreds Pvt Ltd</strong>
          </div>
        </v-col>
        <v-col class="text-center ml-n9" cols="12" md="3" sm="2">
          <u style="cursor: pointer" @click="$router.push('/Terms&Conditions')"
            >Terms & Conditions</u
          >
        </v-col>
        <v-col class="text-center" cols="12" md="2" sm="2">
          <u style="cursor: pointer" @click="$router.push('/PrivacyPolicy')"
            >Privacy Policy</u
          >
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    countCreds: 0,
    items: [
      { title: "Home", value: "Home", icon: "mdi-home" },
      { title: "FAQs", value: "FAQs", icon: "mdi-file-document-multiple" },
      { title: "Sign In", value: "Login", icon: "mdi-login" },
      {
        title: "Verification Requests",
        value: "VerificationRequest",
        icon: "mdi-magnify-scan",
      },
      {
        title: "Verify Credentials",
        value: "VerifyCredentials",
        icon: "mdi-book-search",
      },
    ],
  }),
  watch: {
    "$route.name"() {
      this.itemsFilter = this.items.filter(
        (item) => item.value != this.$route.name
      );
    },
  },
  mounted() {
    if (this.$route.name != "Home") {
      this.$router.push("Home");
    }
    this.itemsFilter = this.items.filter(
      (item) => item.value != this.$route.name
    );
  },
  methods: {
    pushToRoutes(ROUTES) {
      if (ROUTES != this.$route.name) {
        switch (ROUTES) {
          case "Home":
            this.$router.push("Home");
            break;
          case "FAQs":
            this.$router.push("FAQs");
            break;
          case "Login":
            this.$router.push("Login");
            break;
          case "VerificationRequest":
            this.$router.push("VerificationRequest");
            break;
          case "VerifyCredentials":
            this.$router.push("VerifyCredentials");
            break;
        }
      } else {
        this.countCreds += 1;
      }
    },
  },
};
</script>

<style>
html,
body {
  overflow-y: hidden;
}

.v-main__wrap {
  height: calc(100vh - 65px);
  overflow-y: auto;
  overflow-x: hidden;
}

#content {
  height: calc(100vh - 65px);
  overflow-y: hidden;
}
</style>
