import { API } from "aws-amplify";
export const GetSearchedIssuer = {
  data() {
    return {
      searchResultItems: [],
      noDataText:"",
      loading: false,
    };
  },
  methods: {
    async searchIssuers(val) {
      var self = this;
      self.loading = true
      const path = "searchissuer";
      const myInit = {
        body: {
          command: "searchIssuer",
          search_key: val,
        },
      };
      API.post("SURECREDS", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            self.searchResultItems = response.data.items;
          } else {
            self.searchResultItems = [];
            self.noDataText = response.errorMessage;
          }
          self.loading = false
        })
        .catch(() => {
          self.loading = true;
        });
    },
  },
};
